import { computed, getCurrentInstance, ref } from '@vue/composition-api'

export default function useNewsCategoriesList() {
  const vm = getCurrentInstance().proxy

  const newsCategoriesListTable = ref([])

  const tableColumns = [
    { text: '#', value: 'id', width: '5%' },
    { text: 'NAME', value: 'name' },
    { text: 'ARTICLES', value: 'articlesCount', width: '15%' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
      width: '10%',
    },
  ]

  const newsCategories = computed({
    get: () => vm.$store.getters['newsCategories/items'],
  })
  const loading = computed({
    get: () => vm.$store.getters['newsCategories/loading'],
  })
  const meta = computed({
    get: () => vm.$store.getters['newsCategories/meta'],
  })
  const filters = computed({
    get: () => vm.$store.getters['newsCategories/filters'],
  })
  const options = computed({
    get: () => vm.$store.getters['newsCategories/options'],
    set: val => vm.$store.dispatch('newsCategories/setOptions', { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters['newsCategories/search'],
    set: val => vm.$store.dispatch('newsCategories/setSearch', val),
  })
  const totalNewsCategoriesListTable = computed({
    get: () => vm.$store.getters['newsCategories/meta'].total,
  })

  const fetchItems = () => vm.$store.dispatch('newsCategories/fetchItems')
  const setOptions = val => vm.$store.dispatch('newsCategories/setOptions', val)
  const setFilters = val => vm.$store.dispatch('newsCategories/setFilters', val)

  const init = () => {
    fetchItems()
  }

  const destroy = () => {}

  return {
    newsCategoriesListTable,
    tableColumns,

    totalNewsCategoriesListTable,
    loading,

    fetchItems,

    meta,
    newsCategories,
    options,
    filters,
    search,

    setOptions,
    setFilters,

    init,
    destroy,
  }
}
